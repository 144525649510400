<template>
  <div class="noselect margins3">
    <div>
      <h3 class="pointer" @click="followLink('/passchange')">Смена пароля</h3>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },
  },
};
</script>
